<template>
<div class="search_box" ref="search_box">
  <p class="header_xq">搜索详情：</p>
  <div class="serch_input">
    <el-input
      v-model="from.keywords"
      class="input1"
      size="large"
      placeholder="请输入你要搜索的关键词"
      :suffix-icon="Search"
    />
    <el-button size="large" type="primary" color="#174994" :icon="Search" @click="result"></el-button>
  </div>
    <nav class="top" v-if="searchResult_show">您搜索“<span>{{searchResult}}</span>”的结果为：</nav>
    <div class="nav_list">
      <ul>
        <li v-for="(item,i) in serachList" :key="i" @click="pushArticle_xq(item)">
          <img src="https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618259YxAm28" class="top_no_topNew">
          <span>{{item.title}}</span>
          <a>{{item.created_at?item.created_at.slice(0,10):''}}</a>
        </li>
      </ul>
      <div class="null" v-if="null_show"> <el-empty :image-size="300" description="未找到搜索内容..."/></div>
      <!-- 底部分页,-->
      <div class="pagination">
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
      </div>
    </div>
</div>  
</template>

<script setup>
import Pagination from '@/components/Pagination.vue'
import base64_ from '@/hooks/useBase64text.js'
import { ref,onMounted, reactive,watch,onUnmounted, } from 'vue'
import { ElNotification  } from 'element-plus'
import * as http from '@/api/API.js'
import { Search} from '@element-plus/icons-vue'
import { useRouter,useRoute } from 'vue-router'
import miiter from '@/utils/miitbus.js'
const baseUrl = process.env.VUE_APP_BASE_URL
const route = useRoute()
const router = useRouter()
let routeData = JSON.parse(base64_.decode(route.params.obj))
const search_box = ref('')
let inputValue = ref("null")
let searchResult = ref("")
let searchResult_show = ref(true)
let serachList = ref([])
let null_show = ref(false)
let from = reactive({
  keywords:JSON.parse(window.sessionStorage.getItem("key")),
  page:1,
  limit:10,
  total:0
})

// 把page缓存到本地，以免页面刷新回到第一页
let editFrompage = () => {
  const sessStor = JSON.parse(window.sessionStorage.getItem('page'))
  if(sessStor){
    from.page = sessStor
    getsearchList(from)
  }else{
    from.page = 1
    getsearchList(from)
  } 
}

onMounted(() => {
// getsearchList(from)
editFrompage()
})
// 做动画的显示页面
let animati_on = () => {
  if(search_box.value){
    search_box.value.style.transform = 'translateY(0)'
    search_box.value.style.opacity = '1'
  }
}
let getsearchList = (data) => {
  setTimeout(() => { animati_on() },500)
  http.getHttp("/api/article",data).then(res => {
    res.data.data.data.length>0?null_show.value=false:null_show.value=true
    serachList.value = res.data.data.data
     // 把搜索结果和路由的keywords都换成文本内容，防止刷新还是老数据
    searchResult.value = from.keywords || JSON.parse(window.sessionStorage.getItem("key"))
    from.total = res.data.data.total
  })
}
// 点击搜索
let result = () => {
  if(from.keywords.trim()){
    window.sessionStorage.setItem("key",JSON.stringify(from.keywords))
    searchResult_show.value = true
    getsearchList(from)
  }else{
    ElNotification({
    title: '警告',
    message: '搜索内容不能为空！！！',
    type: 'warning',
    zIndex:99999999999
  })
  }
  
}
let pageChanges = (e) => {
  from.page = e
  window.sessionStorage.setItem('page',e)
  editFrompage()
}
  // 点击li跳转到详情
let pushArticle_xq = (item) => {
  // 如果item.is_direct_down为1就直接下载，不跳转
  if(item.is_direct_down == 1){
    const url_ = item.pic_url[0]
    if(url_){
      window.open(baseUrl+url_.url)
    }else{
    ElNotification({
    title: '提示',
    message: '未上传附件...',
    type: 'warning',
    position: 'top-right',
    zIndex:'99999999999'
  })
  }
    return
  }
    router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      article_wz_id:item.id,
      title1:routeData.title1,
      id:routeData.id,
    }))}
  })
}
miiter.on('seachKeyword',(res) =>{
  from.keywords = JSON.parse(window.sessionStorage.getItem("key"))
  result()
})
onUnmounted(() => {
  if(!route.params.obj) return
  if(!JSON.parse(base64_.decode(route.params.obj)).article_wz_id){
     window.sessionStorage.setItem('page',1)
  }
})
</script>
<style lang="less" scoped>
@maxw:128rem;
.search_box{
  width: 100%;
  margin: 0 30/@maxw;
  transform: translateY(500px);
  transition: ease .8s;
  opacity: 0;
  .header_xq{
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 20/@maxw 0;
  }
  .serch_input{
    margin-top: 30/@maxw;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input1{
    width: 100% !important;
    margin-right: 10/@maxw;
  }
  }
  .top{
    width: 100%;
    font-size: 25/@maxw;
    margin-top: 20/@maxw;
    >span{
      color: #fd6161;
      font-weight: bold;
      font-size: 27/@maxw;
    }
  }
  .nav_list{
    margin: 50/@maxw 0;
    width: 100%;
    padding: 0;
    >ul{
      margin: 0;
      padding: 0;
      >li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50/@maxw;
        margin: 10/@maxw 0;
        border-bottom: 1/@maxw dotted #ccc;
        transition: ease .3s;
        padding: 0 0 0 10/@maxw;
        cursor: pointer;
        // .top_top_new{
        //   width: 40/@maxw;
        //   height: 45%;
        // }
        .top_no_topNew{
          // margin: 0 0 0 10/@maxw;
          width: 13/@maxw;
          height: 38%;
        }
        >span{
          flex: 1;
          margin: 0 15/@maxw 0 20/@maxw;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #5A5A5A;
        }
        >a{
          margin: 0;
          padding: 0;
          width: 115/@maxw;
          white-space: nowrap;
        }
      }
       
      >li:hover{
        box-shadow: 0 0 8/@maxw rgb(90, 90, 90);
        margin-left: 3/@maxw;
      }
    }
  }
}
  .pagination{
  // margin: 40/@maxw 0 0 40%; 
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .search_box{
    margin: 0;
    .header_xq{
      font-size: 35/@maxw_;
    }
    .top{
      margin-top: 40/@maxw_;
      font-size: 35/@maxw_;
      >span{
      font-size: 38/@maxw_;
    }
    }
  }
  .nav_list{
    ul{
      margin: 0 !important;
      font-family: Source Han Sans CN-Medium;
      li{
        height: 100/@maxw_ !important;
        .top_no_topNew{
          margin-right: 2px;
          width: 2vw !important;
          height: 24% !important;
        }
        >span{
          font-size: 30/@maxw_ !important;
        }
        >a{
          width: 115/@maxw_ !important;
          font-size: 26/@maxw_ !important;
          margin-right: 40/@maxw_ !important;
        }
      }
    }
  }
}
</style>